@use '../assets' as a;

.home {
	background-color: a.$primary;
}

.container {
	padding: 0 2rem;
}

@media screen and (min-width: 768px) {
	.flex-md {
		display: flex;
	}
}


.main {
	min-height: 100vh;
	padding: 4rem 0;
	flex: 1;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

caption {
	display: inline;
}

.footer {
	display: flex;
	flex: 1;
	flex-wrap: wrap;
	padding: 2rem 0;
	border-top: 1px solid #eaeaea;
	justify-content: space-between;
	align-items: center;
}


.footer a {
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-grow: 1;
}

.title a {
	color: #0070f3;
	text-decoration: none;
}

.title a:hover,
.title a:focus,
.title a:active {
	text-decoration: underline;
}

.title {
	margin: 0;
	line-height: 1.15;
	font-size: 4rem;
	padding: 1rem 0;
}

.title,
.description {
	text-align: center;
}

.description {
	margin: 0;
	line-height: 1.5;
	//font-size: 1.6rem;
}

.code {
	background: #fafafa;
	border-radius: 5px;
	padding: 0.75rem;
	font-size: 1.1rem;
	font-family: Menlo, Monaco, Lucida Console, Liberation Mono, DejaVu Sans Mono,
		Bitstream Vera Sans Mono, Courier New, monospace;
}

.grid {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-wrap: wrap;
	max-width: 800px;
}

.card {
	margin: 1rem;
	padding: 2rem;
	text-align: left;
	color: inherit;
	text-decoration: none;
	border-radius: 2px;
	transition: color 0.15s ease, border-color 0.15s ease;
}

@media screen and (min-width: 768px) {
	.card {
		max-width: 450px;
	}
}

.card:hover,
.card:focus,
.card:active {
	color: #0070f3;
	border-color: #0070f3;
}

.card h2 {
	margin: 0 0 1rem 0;
}

.card p {
	margin: 0;
}

.logo {
	height: 1em;
	margin-left: 0.5rem;
}

@media (max-width: 600px) {
	.grid {
		width: 100%;
		flex-direction: column;
	}
}

// @media (prefers-color-scheme: dark) {
// 	.card,
// 	.footer {
// 		border-color: #222;
// 	}
// 	.code {
// 		background: #111;
// 	}
// 	.logo img {
// 		filter: invert(1);
// 	}
// }

.flex1200 {
	display: flex;
	gap: 1rem;
	flex-wrap: wrap;
	align-items: flex-start;
	justify-content: center;
}


//BLOCKQ

// @import "compass/css3";

html {
  background: #eff0ef;
}

a {
	color: lightblue;
}

h1 {
  font-size: 16px; 
  text-align: center;
  margin-bottom: 40px;
}

.testimonial-quote {
    font-size: 16px;
	margin: 1.9rem 0;
}

.testimonial-quote blockquote {
    /* Negate theme styles */
    border: 0;
    margin: 0;
    padding: 0;

    background: none;
    // color: gray;
    font-family: "Gantari", sans-serif; 
    font-size: 1.5em;
    font-style: italic;
    line-height: 1.4 !important;
    margin: 0;
    position: relative;
    text-shadow: 0 1px white;
    z-index: 600;
}

.testimonial-quote blockquote * {
    box-sizing: border-box; 
}

.testimonial-quote blockquote p {
    // color: #75808a; 
    line-height: 1.4 !important;
}

.testimonial-quote blockquote p:first-child:before {
    content: '\201C';
    color: #81bedb;
    font-size: 7.5em;
    font-weight: 700;
    opacity: .3;
    position: absolute;
    top: -.4em;
    left: -.2em;    
    text-shadow: none;
    z-index: -300;
}

.testimonial-quote img {
    border: 3px solid #9CC1D3;
    border-radius: 50%;
    display: block;
    width: 120px;
    height: 120px;
    position: absolute;
    top: -.2em; 
    left: 0;    
}

.testimonial-quote cite {
    // color: gray;
    display: block;
    font-size: .8em; 
}

@media screen and (min-width: 768px) {
	
	.flex-768 {
		display: flex;
		justify-content: space-between;
		gap: 2rem;
	}

}
  
.testimonial-quote cite span {
    // color: #5e5e5e;
    font-size: 1em;
    font-style: normal;
    font-weight: 700;
    letter-spacing: 1px;
    text-transform: uppercase;
    text-shadow: 0 1px white; 
}

.testimonial-quote {
    position: relative; 
}

.testimonial-quote .quote-container {
    margin: 0 auto 2rem auto; 
	padding: 1rem;
}

.testimonial-quote.right img {
    left: auto;
    right: 0;
}

.testimonial-quote.right cite {
    text-align: right; 
}

//BLOCKQ/