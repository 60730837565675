
.smltxt {
	font-size: 14px;
}

body h1,
body h2 {
	font-family: 'Charm', Garamond, serif; 
	font-weight: 700;
	line-height: 75px;
	text-align: left;
}
body h1 {
	font-size: 66px;
	color: #fff;
}
body h2 {
	font-size: 50px;
	color: #fff;
}

body p,
body label,
body input,
body a,
body cite,
body h3,
body li {
    font-family: 'Gantari', sans-serif;
	font-size: 19px;
	font-weight: 300;
	line-height: 1.6;
	text-align: left;
	color: #fff;
}

body a {
	color: lightblue;
}

// body li:hover {
// 	color: #131313;
// }
// p.m-w65 {
// 	max-width: 65rem;
// }

.errors {
    font-family: 'Gantari', sans-serif;
	font-size: 1.4rem;
	padding-top: 0.5rem;
}

.inner-container {
	margin: 8rem 1.6rem 0rem;
	max-width: 100rem;
	margin: 0 auto;
}
