//colors
$off-black: #131313;
$primary: #370642;
$secondary: #921459;
$off-white: #f1f1f1;
$gray: #cec8c3;

//hover states
$off-black-hover: #323232;
$secondary-hover: #aa7e60;

.primary {
	color: $primary;
}

.off-black {
	color: $off-black;
}

.off-white {
	color: $off-white;
}

//original green: test this #0F2E24
