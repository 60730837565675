@use '../assets' as a;

.title,
.description {
	color: a.$off-white;
}

.devbanner {
	padding: 5rem;
	border-radius: 2px;
	background: a.$secondary;
	box-shadow: inset 18px 18px 14px a.$primary, inset -18px -18px 14px a.$primary;
	margin: 1rem 1.6rem 0rem 1.6rem;
	max-width: 140rem;

	img {
		width: 100%;
		object-fit: contain;
	}
}



@media screen and (min-width: 798px) {
	.bio-flex {
		display: flex;
		align-items: center;
	}

	.description {
		padding-inline: 1rem;
	}


	.flex-800 {
		display: flex;
		flex-direction: row-reverse;
	}

	.devbanner {
		margin: 3rem 1.6rem 0rem 1.6rem;
	}

	.devbanner caption {
		margin-left: 1.4rem;
	}
}
// @media screen and (min-width: 898px) {
// 	.devbanner {
// 		padding: 8rem;
// 		display: flex;
// 		flex-direction: row-reverse;
// 		align-items: center;
// 		justify-content: space-between;
// 		gap: 1rem;
// 		}
// 	}



div > .devbanner {
	color: a.$secondary;
}
