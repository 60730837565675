@use './colors' as c;
@use './fonts' as f;

:root,
html {
	//font-size: 62.5%;
	/* 10/16 = 0.625 * 100 = 62.5%, so we're working in a base of 10 for font size.*/

	scroll-behavior: smooth;
}

