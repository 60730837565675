//super crazy reset that I've put together over time. Does a lot of corrections that we would otherwise have to deal with in other/older browsers.

:root,
html {
	line-height: 1.15;
	font-size: 62.5%; /* 10/16 = 0.625 * 100 = 62.5%, so we're working in a base of 10 for font size.*/
	-webkit-text-size-adjust: 100%;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
	display: block;
}

ol,
ul {
	list-style: none;
}

/**
   * 1. Add the correct box sizing in Firefox.
   * 2. Show the overflow in Edge and IE.
   */
hr {
	box-sizing: content-box;
	height: 0;
	overflow: visible;
}

/**
   * Remove the gray background on active links in IE 10.
   */
a {
	background-color: transparent;
	text-decoration: none;
}

/**
   * 1. Remove the bottom border in Chrome 57-
   * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
   */
abbr[title] {
	border-bottom: none;
}

/**
   * Add the correct font weight in Chrome, Edge, and Safari.
   */
b,
strong {
	font-weight: bolder;
}

/**
   * Remove the border on images inside links in IE 10.
   */
img {
	border-style: none;
}

/**
   * 1. Change the font styles in all browsers.
   * 2. Remove the margin in Firefox and Safari.
   */
button,
input,
optgroup,
select,
textarea {
	font-family: inherit;
	margin: 0;
}

/**
   * Show the overflow in IE.
   * 1. Show the overflow in Edge.
   */
button,
input {
	overflow: visible;
}

/**
   * Remove the inheritance of text transform in Edge, Firefox, and IE.
   * 1. Remove the inheritance of text transform in Firefox.
   */
button,
select {
	text-transform: none;
}

/**
   * Correct the inability to style clickable types in iOS and Safari.
   */
button,
[type='button'],
[type='reset'],
[type='submit'] {
	-webkit-appearance: button;
}

/**
   * Correct the padding in Firefox.
   */
fieldset {
	padding: 0.35em 0.75em 0.625em;
}

/**
   * Remove the default vertical scrollbar in IE 10+.
   */
textarea {
	overflow: auto;
}

/**
   * 1. Add the correct box sizing in IE 10.
   * 2. Remove the padding in IE 10.
   */
[type='checkbox'],
[type='radio'] {
	box-sizing: border-box;
	padding: 0;
}

/**
   * Correct the cursor style of increment and decrement buttons in Chrome.
   */
[type='number']::-webkit-inner-spin-button,
[type='number']::-webkit-outer-spin-button {
	height: auto;
}

/**
   * 1. Correct the odd appearance in Chrome and Safari.
   * 2. Correct the outline style in Safari.
   */
[type='search'] {
	-webkit-appearance: textfield;
	outline-offset: -2px;
}

/**
   * Remove the inner padding in Chrome and Safari on macOS.
   */
[type='search']::-webkit-search-decoration {
	-webkit-appearance: none;
}

/**
   * 1. Correct the inability to style clickable types in iOS and Safari.
   * 2. Change font properties to `inherit` in Safari.
   */
::-webkit-file-upload-button {
	-webkit-appearance: button;
	font: inherit;
}

/**
   * Add the correct display in IE 10.
   */
[hidden] {
	display: none;
}

/* Inherit box-sizing to more easily change it's value on a component level.
  @link http://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/ */
*,
*::before,
*::after {
	box-sizing: inherit;
}

html {
	box-sizing: border-box;
}
